var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isWideScreen)?_c('VNavigationDrawer',{class:[
      'layout__default__sidebar',
      {
        'layout__default__sidebar--mini-variant': _vm.miniVariant
      }
    ],attrs:{"mini-variant":_vm.miniVariant,"mini-variant-width":80,"width":200,"color":"secondary","permanent":"","touchless":"","app":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('VList',{attrs:{"height":"72"}},[_c('VListItem',[_c('VListItemContent',[_c('VTooltip',{attrs:{"bottom":"","color":"info","open-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('RouterLink',{staticClass:"d-flex justify-center",attrs:{"to":"/","aria-label":_vm.$t('commons.standards.home')}},[_c('Transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.miniVariant)?_c('VImg',_vm._g({key:"logo-digitsolepro-simple-white",staticClass:"layout__default__sidebar__logo",attrs:{"src":_vm.cdn('image', 'logo-baliston-simple-white.svg'),"max-width":30,"contain":""}},on)):_c('VImg',_vm._g({key:"logo-digitsolepro-full-white",staticClass:"layout__default__sidebar__logo",attrs:{"src":_vm.cdn('image', 'logo-baliston-full-white.svg'),"max-width":160,"contain":""}},on))],1)],1)]}}],null,false,2525209763)},[_vm._v(" "+_vm._s(_vm.$t('commons.standards.home'))+" ")])],1)],1)],1)]},proxy:true},{key:"append",fn:function(){return [_c('div',{staticClass:"text-center mt-1 mb-7"},[_c('VTooltip',{attrs:{"color":"info","right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('VBtn',_vm._g({staticClass:"fa-fw",class:{ miniVariant: _vm.miniVariant },attrs:{"color":"white","outlined":"","width":"24","height":"24","fab":"","aria-label":_vm.$t(("commons.actions." + (_vm.miniVariant ? 'maximize' : 'minimize')))},on:{"click":function($event){return _vm.toggleMiniVariant()}}},on),[_c('GlobalIcon',{staticStyle:{"padding-right":"3px"},attrs:{"icon":"chevron-left","library":"regular","color":"white","size":10}})],1)]}}],null,false,1520486324)},[_vm._v(" "+_vm._s(_vm.$t(("commons.actions." + (_vm.miniVariant ? 'maximize' : 'minimize'))))+" ")])],1),_c('div',{staticClass:"text-center mt-1 mb-7 white--text"},[_c('VTooltip',{attrs:{"color":"info","disabled":!_vm.miniVariant,"right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('div',_vm._g({staticClass:"layout__default__sidebar__version"},on),[_c('Transition',{attrs:{"name":"fade","mode":"out-in"}},[(!_vm.miniVariant)?_c('div',[_vm._v(" "+_vm._s(_vm.updateVersion)+" ")]):_c('GlobalIcon',{attrs:{"icon":"code","library":"regular","color":"white","size":16}})],1)],1)]}}],null,false,3137207243)},[(_vm.miniVariant)?_c('span',[_vm._v(" "+_vm._s(_vm.updateVersion)+" ")]):_vm._e()])],1)]},proxy:true}],null,false,4274090894)},[_c('VList',{staticClass:"layout__default__sidebar__list mt-10"},[_vm._l((_vm.links),function(link,name){return [(!link.disabled)?_c('VTooltip',{key:name,attrs:{"color":"info","disabled":!_vm.miniVariant,"right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('VListItem',_vm._g({staticClass:"layout__default__sidebar__list__item",attrs:{"to":{ name: name },"ripple":false,"active-class":"layout__default__sidebar__list__item--active","exact":name === 'home',"link":""}},on),[_c('VListItemIcon',{staticClass:"layout__default__sidebar__list__item__icon"},[_c('GlobalIcon',{attrs:{"icon":link.icon,"color":"white","size":16}})],1),_c('VListItemContent',[_c('VListItemTitle',{staticClass:"layout__default__sidebar__list__item__title"},[_vm._v(_vm._s(link.title))])],1)],1)]}}],null,true)},[_vm._v(" "+_vm._s(link.title)+" ")]):_vm._e()]}),_c('VTooltip',{attrs:{"color":"info","disabled":!_vm.miniVariant,"right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('VListItem',_vm._g({staticClass:"layout__default__sidebar__list__item",attrs:{"href":_vm.supportLink,"ripple":false,"active-class":"layout__default__sidebar__list__item--active","target":"_blank","link":""},on:{"click":function($event){return _vm.trackSuccess('Go to support')}}},on),[_c('VListItemIcon',{staticClass:"layout__default__sidebar__list__item__icon"},[_c('GlobalIcon',{attrs:{"icon":"question-circle","color":"white","size":16}})],1),_c('VListItemContent',[_c('VListItemTitle',{staticClass:"layout__default__sidebar__list__item__title"},[_vm._v(_vm._s(_vm.$t('commons.standards.support')))])],1)],1)]}}],null,false,3444749836)},[_vm._v(" "+_vm._s(_vm.$t('commons.standards.support'))+" ")])],2)],1):_c('VBottomNavigation',{attrs:{"height":60,"fixed":"","shift":"","grow":"","background-color":"secondary"}},[_vm._l((_vm.links),function(link,name){return [(!link.disabled)?_c('VBtn',{key:name,attrs:{"to":{ name: name },"active-class":"active","color":"white","text":"","exact":""}},[_c('span',{staticClass:"text-unset caption text--white font-weight-semibold"},[_vm._v(_vm._s(link.title))]),_c('GlobalIcon',{staticClass:"v-icon test",attrs:{"icon":link.icon,"color":"white"}})],1):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }